import { __decorate } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
let ShareInvite = class ShareInvite extends Vue {
    constructor() {
        super(...arguments);
        this.copySuccess = false;
    }
    get inviteLink() {
        if (process.env.SHORT_URL) {
            return `https://${process.env.SHORT_URL}/${this.$route.params.roomId}`;
        }
        return window.location.href.split('?')[0].toLowerCase();
    }
    async copyInviteLink() {
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(this.inviteLink);
        }
        else {
            // @ts-expect-error $el actually does exist
            let textfield = this.$refs.inviteLinkText.$el.querySelector('input');
            if (!textfield) {
                console.error("failed to copy link: input not found");
                return;
            }
            textfield.select();
            document.execCommand("copy");
            setTimeout(() => {
                this.copySuccess = false;
                textfield === null || textfield === void 0 ? void 0 : textfield.blur();
            }, 3000);
        }
        this.copySuccess = true;
    }
    onFocusHighlightText(e) {
        e.target.select();
    }
};
ShareInvite = __decorate([
    Component({
        name: "ShareInvite",
    })
], ShareInvite);
export default ShareInvite;
