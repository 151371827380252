import { __decorate } from "tslib";
import _ from "lodash";
import Component, { mixins } from "vue-class-component";
import PermissionsEditor from "@/components/PermissionsEditor.vue";
import { ToastStyle } from "@/models/toast";
import { API } from "@/common-http.js";
import { Visibility, QueueMode } from 'common/models/types';
import PermissionsMixin from "@/mixins/permissions";
let RoomSettingsForm = class RoomSettingsForm extends mixins(PermissionsMixin) {
    constructor() {
        super(...arguments);
        this.Visibility = Visibility;
        this.QueueMode = QueueMode;
        this.isLoadingRoomSettings = false;
        this.inputRoomSettings = {
            title: '',
            description: '',
            visibility: Visibility.Public,
            queueMode: QueueMode.Manual,
            grants: {},
            autoSkipSegments: true,
        };
    }
    mounted() {
        this.loadRoomSettings();
    }
    async loadRoomSettings() {
        // we have to make an API request becuase visibility is not sent in sync messages.
        this.isLoadingRoomSettings = true;
        let res = await API.get(`/room/${this.$route.params.roomId}`);
        this.isLoadingRoomSettings = false;
        if (res.data.permissions && !res.data.grants) {
            res.data.grants = res.data.permissions;
        }
        this.inputRoomSettings = _.pick(res.data, "title", "description", "visibility", "queueMode", "grants", "autoSkipSegments");
    }
    getRoomSettingsSubmit() {
        const propsToGrants = {
            title: "set-title",
            description: "set-description",
            visibility: "set-visibility",
            queueMode: "set-queue-mode",
            autoSkipSegments: "set-auto-skip",
        };
        let blocked = [];
        for (let prop of Object.keys(propsToGrants)) {
            if (!this.granted(`configure-room.${propsToGrants[prop]}`)) {
                blocked.push(prop);
            }
        }
        if (this.$store.state.room.isTemporary) {
            blocked.push("grants");
        }
        return _.omit(this.inputRoomSettings, blocked);
    }
    /** Take room settings from the UI and submit them to the server. */
    async submitRoomSettings() {
        this.isLoadingRoomSettings = true;
        try {
            await API.patch(`/room/${this.$route.params.roomId}`, this.getRoomSettingsSubmit());
            this.$toast.add({
                style: ToastStyle.Success,
                content: this.$t("room-settings.settings-applied"),
                duration: 4000,
            });
        }
        catch (e) {
            console.log(e);
            this.$toast.add({
                style: ToastStyle.Error,
                content: e.response.data.error.message,
                duration: 6000,
            });
        }
        this.isLoadingRoomSettings = false;
    }
    async claimOwnership() {
        this.isLoadingRoomSettings = true;
        try {
            await API.patch(`/room/${this.$route.params.roomId}`, {
                claim: true,
            });
            this.$toast.add({
                style: ToastStyle.Success,
                content: this.$t("room-settings.now-own-the-room", { room: this.$route.params.roomId }),
                duration: 4000,
            });
        }
        catch (e) {
            console.log(e);
            this.$toast.add({
                style: ToastStyle.Error,
                content: e.response.data.error.message,
                duration: 6000,
            });
        }
        this.isLoadingRoomSettings = false;
    }
};
RoomSettingsForm = __decorate([
    Component({
        name: 'RoomSettings',
        components: {
            PermissionsEditor,
        },
        mixins: [PermissionsMixin],
    })
], RoomSettingsForm);
export default RoomSettingsForm;
