import { __decorate } from "tslib";
import Vue from "vue";
import { API } from "@/common-http.js";
import { secondsToTimestamp } from "@/util/timestamp";
import { ToastStyle } from '@/models/toast';
import Component from 'vue-class-component';
import { QueueMode } from "common/models/types";
import api from "@/util/api";
let VideoQueueItem = class VideoQueueItem extends Vue {
    constructor() {
        super(...arguments);
        this.isLoadingAdd = false;
        this.isLoadingVote = false;
        this.hasBeenAdded = false;
        this.thumbnailHasError = false;
        this.hasError = false;
        this.voted = false;
        this.QueueMode = QueueMode;
    }
    get videoLength() {
        var _a, _b;
        return secondsToTimestamp((_b = (_a = this.item) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0);
    }
    get thumbnailSource() {
        return !this.thumbnailHasError && this.item.thumbnail ? this.item.thumbnail : require('@/assets/placeholder.svg');
    }
    get votes() {
        var _a, _b;
        return (_b = (_a = this.$store.state.room.voteCounts) === null || _a === void 0 ? void 0 : _a.get(this.item.service + this.item.id)) !== null && _b !== void 0 ? _b : 0;
    }
    created() {
        if (this.item.id === this.$store.state.room.currentSource.id && this.item.service === this.$store.state.room.currentSource.service) {
            this.hasBeenAdded = true;
            return;
        }
        for (let video of this.$store.state.room.queue) {
            if (this.item.id === video.id && this.item.service === video.service) {
                this.hasBeenAdded = true;
                return;
            }
        }
    }
    getPostData() {
        let data = {
            service: this.item.service,
            id: this.item.id,
        };
        // console.log(data);
        return data;
    }
    async addToQueue() {
        this.isLoadingAdd = true;
        try {
            let resp = await API.post(`/room/${this.$route.params.roomId}/queue`, this.getPostData());
            this.hasError = !resp.data.success;
            this.hasBeenAdded = true;
            this.$toast.add({
                style: ToastStyle.Success,
                content: this.$t('video-queue-item.messages.video-added'),
                duration: 5000,
            });
        }
        catch (e) {
            this.hasError = true;
            this.$toast.add({
                style: ToastStyle.Error,
                content: e.response.data.error.message,
                duration: 6000,
            });
        }
        this.isLoadingAdd = false;
    }
    async removeFromQueue() {
        this.isLoadingAdd = true;
        try {
            let resp = await API.delete(`/room/${this.$route.params.roomId}/queue`, {
                data: this.getPostData(),
            });
            this.hasError = !resp.data.success;
            this.$toast.add({
                style: ToastStyle.Success,
                content: this.$t('video-queue-item.messages.video-removed'),
                duration: 5000,
            });
        }
        catch (e) {
            this.hasError = true;
            this.$toast.add({
                style: ToastStyle.Error,
                content: e.response.data.error.message,
                duration: 6000,
            });
        }
        this.isLoadingAdd = false;
    }
    async vote() {
        this.isLoadingVote = true;
        try {
            let resp;
            if (!this.voted) {
                resp = await API.post(`/room/${this.$route.params.roomId}/vote`, this.getPostData());
                this.voted = true;
            }
            else {
                resp = await API.delete(`/room/${this.$route.params.roomId}/vote`, { data: this.getPostData() });
                this.voted = false;
            }
            this.hasError = !resp.data.success;
        }
        catch (e) {
            this.hasError = true;
            this.$toast.add({
                style: ToastStyle.Error,
                content: e.response.data.error.message,
                duration: 6000,
            });
        }
        this.isLoadingVote = false;
    }
    playNow() {
        api.playNow(this.item);
    }
    /**
     * Moves the video to the top of the queue.
     */
    moveToTop() {
        api.queueMove(this.index, 0);
    }
    /**
     * Moves the video to the bottom of the queue.
     */
    moveToBottom() {
        api.queueMove(this.index, this.$store.state.room.queue.length - 1);
    }
    onThumbnailError() {
        this.thumbnailHasError = true;
    }
};
VideoQueueItem = __decorate([
    Component({
        name: "VideoQueueItem",
        props: {
            item: { type: Object, required: true },
            isPreview: { type: Boolean, default: false },
            index: { type: Number, required: false },
        },
    })
], VideoQueueItem);
export default VideoQueueItem;
