var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"user-list"},[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$t("room.users.title"))+" "),_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":_vm.openEditName}},[_c('v-icon',[_vm._v("fas fa-cog")])],1)],1),(_vm.showEditName)?_c('v-list-item',[_c('v-text-field',{attrs:{"placeholder":_vm.$t('room.users.set'),"loading":_vm.setUsernameLoading,"error-messages":_vm.setUsernameFailureText,"counter":_vm.USERNAME_LENGTH_MAX},on:{"change":_vm.onEditNameChange},model:{value:(_vm.inputUsername),callback:function ($$v) {_vm.inputUsername=$$v},expression:"inputUsername"}})],1):_vm._e(),(!_vm.$store.state.permsMeta.loaded)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("room.users.waiting-for-permissions"))+" ")]):_vm._e(),_vm._l((_vm.users),function(user,index){return _c('v-list-item',{key:index,class:("user " + (user.isLoggedIn ? 'registered' : '') + " " + (_vm.$store.state.permsMeta.loaded ? ("role-" + (_vm.$store.state.permsMeta.roles[user.role].name)) : ''))},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(user.name))]),(_vm.$store.state.permsMeta.loaded)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"role",attrs:{"small":"","aria-label":((user.id === _vm.$store.state.users.you.id ? 'you' : user.name) + " is " + (_vm.$store.state.permsMeta.roles[user.role].display))}},[_vm._v(" fas fa-"+_vm._s({"2":"thumbs-up", "3":"chevron-up", "4":"star", "-1":"star" }[user.role])+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$store.state.permsMeta.roles[user.role].display))])]):_vm._e(),(user.id === _vm.$store.state.users.you.id)?_c('span',{staticClass:"is-you"},[_vm._v(_vm._s(_vm.$t("room.users.you")))]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var _obj;

var on = ref.on;
var attrs = ref.attrs;return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"player-status",attrs:{"small":"","aria-label":((user.id === _vm.$store.state.users.you.id ? 'your' : user.name) + " player is " + (user.status))}},[_vm._v(" fas fa-"+_vm._s(( _obj = {}, _obj[_vm.PlayerStatus.buffering] = "spinner", _obj[_vm.PlayerStatus.ready] = "check", _obj[_vm.PlayerStatus.error] = "exclamation", _obj )[user.status])+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(user.status))])]),(user.id !== _vm.$store.state.users.you.id)?_c('div',{staticStyle:{"margin-left":"auto"}},[_c('v-menu',{attrs:{"right":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","tile":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-cog")]),_c('v-icon',{staticStyle:{"margin-left":"5px"},attrs:{"small":"","aria-hidden":""}},[_vm._v("fas fa-caret-down")])],1)]}}],null,true)},[_c('v-list',[(_vm.$store.state.permsMeta.loaded)?_c('div',{key:_vm.$store.state.permsMeta.loaded,staticClass:"user-promotion"},_vm._l((4),function(role){return _c('div',{key:user.role + role},[(user.role !== role && (role <= 1 || _vm.granted(_vm.roleToPermission(role))) && (user.role > 0 && user.role <= 1 || _vm.granted(_vm.roleToPermission(user.role, _vm.demote=true))))?_c('v-list-item',{on:{"click":function($event){return _vm.api.promoteUser(user.id, role)}}},[_vm._v(" "+_vm._s(user.role > role ? _vm.$t("room.users.demote") : _vm.$t("room.users.promote"))+" to "+_vm._s(_vm.$store.state.permsMeta.roles[role].display)+" ")]):_vm._e()],1)}),0):_c('v-row',{attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)],1)],1)],1):_vm._e()],1)}),(_vm.users.length === 1)?_c('v-list-item',{staticClass:"nobody-here"},[_vm._v(" "+_vm._s(_vm.$t("room.users.empty"))+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }