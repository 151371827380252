import { __decorate } from "tslib";
import Vue from "vue";
import Component from 'vue-class-component';
import draggable from 'vuedraggable';
import VideoQueueItem from "@/components/VideoQueueItem.vue";
import api from "@/util/api";
import PermissionsMixin from "@/mixins/permissions";
let VideoQueue = class VideoQueue extends Vue {
    onQueueDragDrop(e) {
        api.queueMove(e.oldIndex, e.newIndex);
    }
};
VideoQueue = __decorate([
    Component({
        name: "VideoQueue",
        components: {
            draggable,
            VideoQueueItem,
        },
        mixins: [PermissionsMixin],
        data() {
            return {
                api,
            };
        },
    })
], VideoQueue);
export default VideoQueue;
